import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faClock, faLocationArrow, faTimes, faTruck, faSave, faCheck, faGlobe, faMobileAlt, faEnvelope, faPhoneAlt, faShoppingCart, faCartArrowDown, faLongArrowAltLeft, faSearch, faChevronDown, faChevronUp ,faRubleSign, faAngleLeft, faPercentage, faSortAmountUp, faSortAmountDown ,faFilter , faUser, faSignOutAlt, faPaperclip, faSortDown, faCircle, faArrowCircleLeft,faWindowRestore, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { faVk } from '@fortawesome/free-brands-svg-icons'
// import { faInstagram, faFacebookF } from '@fortawesome/free-brands-svg-icons'
import { faCopyright, faThumbsUp, faThumbsDown, faFileVideo,faCheckCircle, faTimesCircle } from '@fortawesome/free-regular-svg-icons'

library.add(faClock, faLocationArrow, faTimes, faTruck, faVk, faCopyright, faSave, faCheck, faGlobe, faMobileAlt, faEnvelope, faPhoneAlt, faShoppingCart, faCartArrowDown, faThumbsUp, faThumbsDown, faLongArrowAltLeft, faSearch, faChevronDown, faChevronUp, faRubleSign, faAngleLeft, faPercentage, faSortAmountUp, faSortAmountDown,faFilter , faSignOutAlt, faUser, faPaperclip, faFileVideo, faSortDown,faCircle,faCheckCircle,faTimesCircle,faArrowCircleLeft,faWindowRestore, faInfoCircle)
// library.add(faInstagram, faFacebookF)
//
export default FontAwesomeIcon
