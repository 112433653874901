<template>
  <div
    :class="[
      `alert-${color}`,
      {
        'mb-2': position.includes('top'),
        'mt-2': position.includes('bottom'),
      },
    ]"
    class="position-relative alert w-100 alert-dismissible"
    role="alert"
  >
    <!-- Message -->
    <span v-html="message"></span>

    <!-- Close button -->
    <button
      type="button"
      class="close font-weight-bold"
      data-dismiss="alert"
      aria-label="Close"
      @click="$emit('remove')"
    >
      <span aria-hidden="true">&times;</span>
    </button>

    <!-- Loading bar -->
    <div
      class="loading-bar position-absolute bg-white"
      :style="{
        width: percentageElapsed + '%',
      }"
    />
  </div>
</template>

<script lang="ts">
import { defineToastComponent, TimerMixin } from 'vue-my-toasts'

export default defineToastComponent({
  name: 'BootstrapComponent',

  props: {
    title: {
      type: [String, Boolean],
      required: false,
      default: false,
    },
    duration: {
      default: 10000
    }
  },

  computed: {
    color() {
      switch (this.type) {
        case 'base':
          return 'info'
        case 'warning':
          return 'warning'
        case 'error':
          return 'danger'
        case 'success':
          return 'success'
        default:
          return 'info'
      }
    },
  },
})
</script>

<style lang="scss" scoped>
.loading-bar {
  height: 0.33rem;
  opacity: 75%;
  bottom: 0;
  left: 0;
}

.alert {
  margin: 0;
	padding: 1rem 1rem;
	position: relative;
	border: 1px solid transparent;
	border-radius: .25rem;
  .close {
    position: absolute;
    top: 5px;
    right: 5px;
  }
}

.alert-primary {
	color: #084298;
	background-color: #cfe2ff;
	border-color: #b6d4fe;
}

.alert-success {
	color: #0f5132;
	background-color: #d1e7dd;
	border-color: #badbcc;
}

.alert-warning {
	color: #664d03;
	background-color: #fff3cd;
	border-color: #ffecb5;
}
.alert-danger {
	color: #842029;
	background-color: #f8d7da;
	border-color: #f5c2c7;
}

</style>
